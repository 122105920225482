<template>
  <div
    class="task-evaluation"
    :style="data.paperType == '5' ? `height: ${96 - answerSheetVH}vh` : ''"
  >
    <div
      v-if="!result.redoList || result.redoList.length < 2"
      class="score-cell"
    >
      <div class="label">总得分</div>
      <div class="score">
        <span :class="`text ${getScoreColor(data.score, data.totalScore)}`">{{
          data.score
        }}</span>
        <span class="total">/{{ data.totalScore }}</span>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-49">
        <div class="score-cell">
          <div class="label">总分</div>
          <div class="score">
            <span
              :class="`text ${getScoreColor(data.score, data.totalScore)}`"
              >{{ data.score }}</span
            >
            <span class="total">/{{ data.totalScore }}</span>
          </div>
        </div>
      </div>
      <div class="col-49">
        <div class="score-cell">
          <div class="label">平均分</div>
          <div class="score">
            <span
              :class="`text ${getScoreColor(
                result.averageScore,
                data.totalScore
              )}`"
              >{{ result.averageScore }}</span
            >
            <span class="total">/{{ data.totalScore }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="redo-cell" v-if="result.redoList && result.redoList.length > 1">
      <div class="redo-cell-header row">
        <div class="col-40">完成时间</div>
        <div class="col-30">完成用时</div>
        <div class="col-30">成绩（{{ data.totalScore }}）</div>
      </div>
      <div
        v-for="item in result.redoList"
        :key="item.id"
        :class="`redo-cell-item row ${
          item.id == data.id ? 'redo-bold-row' : ''
        }`"
        @click="toTask(item)"
      >
        <div class="col-40">{{ item.completeTime }}</div>
        <div class="col-30">{{ Math.ceil(item.duringTime / 60) }}分钟</div>
        <div
          :class="`col-30 redo-cell-score ${getScoreColor(
            item.score,
            data.totalScore
          )}`"
        >
          {{ item.score }}
        </div>
        <van-icon class="arrow-right" name="arrow" v-if="item.id != data.id" />
      </div>
    </div>
    <div class="comment-cell" v-if="result.comment">
      <div class="comment-title">评语</div>
      <div class="comment-content">{{ result.comment }}</div>
    </div>
    <!-- <div class="row">
      <div class="score-cell">
        <div class="label">总分</div>
        <div class="score">
          <span class="text green">70</span>
          <span class="total">/{{data.totalScore}}</span>
        </div>
      </div>
    </div>-->
    <div
      :class="`evaluation-detail ${data.paperType == '5' ? 'pb1' : ''}`"
      id="evaluation-detail"
    >
      <div v-html="data.content"></div>
      <AnswerSheet
        :showBtn="false"
        v-if="data.paperType == '5'"
        :answerSheetVH="answerSheetVH"
        :data="data"
      />
    </div>
  </div>
</template>

<script>
import insert from "../util/insertDOM";
import { mapQuery } from "../util/URLUtils";
import AnswerSheet from "../components/AnswerSheet";
import { ImagePreview, Dialog } from "vant";
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
    result: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      answerSheetVH: 50,
      radioInputList: null,
      textInputList: null,
      textareaInputList: null,
    };
  },
  created() {
    if (this.result.totalScore < this.data.scoreForAnswer) {
      Dialog.confirm({
        title: "提示",
        message: `这次作业需要达到 <span class="green">${this.data.scoreForAnswer}</span> 分才能显示答案，继续努力哦`,
        allowHtml: true,
        showCancelButton: false,
      }).then(() => {});
    }
  },
  mounted() {
    const that = this;
    this.radioInputList = document.querySelectorAll('input[type="radio"]');
    this.textInputList = document.querySelectorAll('input[type="text"]');
    this.textareaInputList = document.querySelectorAll("textarea");
    setTimeout(() => {
      const imgDomList = document.querySelectorAll("#evaluation-detail img");
      if (imgDomList && imgDomList.length) {
        for (let i = 0; i < imgDomList.length; i++) {
          const item = imgDomList[i];
          item.addEventListener("click", (e) => {
            const src = e.target.src;
            ImagePreview({
              images: [src],
              closeable: true,
            });
            console.log("imgDomList click", e.target.src);
          });
        }
      }
      for (let i = 0; i < this.result.subResults.length; i++) {
        const item = this.result.subResults[i];
        const domList = document.querySelectorAll(`[name="${item.tagId}"]`);
        if (domList && domList.length > 0) {
          // 选择题 domList是所有radio
          if (domList.length > 1) {
            for (let j = 0; j < domList.length; j++) {
              const tag = domList[j];
              const parent = tag.parentNode;
              const remarkNode = document.createElement("span");
              if (item.remark) {
                remarkNode.className += " green";
                remarkNode.innerHTML = `（注释：${item.remark}）`;
              }
              if (tag.value == item.answer) {
                tag.checked = true;
                if (item.answer == item.standardAnswer) {
                  parent.className += " green";
                  parent.appendChild(remarkNode);
                } else {
                  parent.className += " red";
                }
              }
              if (
                tag.value == item.standardAnswer &&
                item.answer != item.standardAnswer
              ) {
                parent.className += " green";
                parent.appendChild(remarkNode);
              }
            }
            // 填空题 domList是所有输入框
          } else if (item.tagId.includes("text")) {
            // let _s = this.getNewSpanUtil(s,mapTag);
            // tag.replaceChild(_s,s);
            const tag = domList[0];
            const parent = tag.parentNode;
            tag.value = item.answer || "";
            let inputWidth = 120;
            let answerList = []
            if(item.answer){
              answerList = item.answer.split(' ')
            }
            if(answerList.length>2){
              inputWidth = answerList.length*55
            }
            tag.style.width = `${inputWidth}px`
            if (item.subScore) {
              tag.className += " green";
            } else {
              tag.className += " red";
            }
            if (item.remark) {
              const remarkNode = document.createElement("span");
              remarkNode.className += " green";
              remarkNode.innerHTML = `注释：${item.remark}`;
              parent.insertBefore(remarkNode, tag.nextSibling);
            }
            if (item.answer != item.standardAnswer) {
              const newNode = document.createElement("span");
              newNode.className += " green";
              newNode.innerHTML = item.standardAnswer
                ? `(答案：${item.standardAnswer})`
                : "";
              parent.insertBefore(newNode, tag.nextSibling);
            }
            // 作文题 domList是所有textarea
          } else if (item.tagId.includes("area")) {
            const tag = domList[0];
            const parent = tag.parentNode;
            const newNode = document.createElement("div");
            newNode.className += " evaluation-area";
            const content = document.createElement("div");
            const holder2 = document.createElement("div");
            content.className += " area-content";
            holder2.className += " holder2";
            tag.className += " dn";
            if (!item.correctData || !item.correctData.sentenceComments) {
              continue;
            }
            const correctData = item.correctData;
            const sentenceComments = item.correctData.sentenceComments;
            let answer = item.answer;
            for (let j = 0; j < sentenceComments.length; j++) {
              const comment = sentenceComments[j];
              let sentence = comment.sentence.sentence;
              const sentenceArr = sentence.split(" ");
              const deletedErrorArr = sentenceArr.slice();
              for (let k = 0; k < comment.suggestions.length; k++) {
                const sug = comment.suggestions[k];
                // errorPosition是按单词对应的索引，所以先把sentence转成单词数组
                const [error0, error1] = sug.errorPosition;
                const errorArr = sentenceArr.slice(error0, error1 + 1);
                deletedErrorArr.splice(error0, error1 - error0 + 1);
                // 根据修改建议 修改errorArr
                // console.log("sentenceArr", sentenceArr);
                // console.log("errorArr", errorArr);
                // console.log("deletedErrorArr", deletedErrorArr);
                // console.log("sug.origin", sug.origin);
                // 修改后的句子
                let hintText = "";
                if (sug.origin) {
                  hintText = `${errorArr
                    .join(" ")
                    .replace(
                      sug.origin,
                      `<span class="red">${sug.origin}`
                    )}</span><span style="font-weight: bold">（${
                    sug.message
                  }）</span>`;
                } else {
                  if (errorArr.length == 2) {
                    hintText = `${errorArr[0]}<span style="font-weight: bold">（${sug.message}）</span>${errorArr[1]}`;
                  } else {
                    hintText = `<span class="red">${errorArr.join(
                      " "
                    )}</span><span style="font-weight: bold">（${
                      sug.message
                    }）</span>`;
                  }
                }
                // 把带建议的句子插入数组中
                deletedErrorArr.splice(error0, 0, hintText);
                // 恢复原数组应有的长度 保证errorPosition的位置正确
                if (error1 - error0 > 0) {
                  for (let k = 0; k < error1 - error0; k++) {
                    deletedErrorArr.splice(error0, 0, "");
                  }
                }
                const newSentence = deletedErrorArr.join(" ");
                answer = answer.replace(sentence, newSentence);
                sentence = newSentence
                // if (sug.origin) {
                //   const newSentence = sentence.replace(
                //     sug.origin,
                //     `<span class="red">${sug.origin}</span><span style="font-weight: bold">（${sug.message}）</span>`
                //   );
                //   answer = answer.replace(sentence, newSentence);
                // } else {
                //   const newSentence = `${comment.sentence.sentence}<span style="font-weight: bold">（${sug.message}）</span>`;
                //   answer = answer.replace(sentence, newSentence);
                // }
              }
            }

            content.innerHTML = answer;
            content.append(holder2);
            content.append(
              insert.scoreCell({
                score: [item.subScore],
                total: [item.subTotalScore],
                count: 1,
                label: ["单题总分"],
              })
            );
            const scoreCat = item.correctData.scoreCat;
            content.append(
              insert.scoreCell({
                score: [scoreCat.content.score, scoreCat.sentences.score],
                total: [100, 100],
                count: 2,
                label: [scoreCat.content.name, scoreCat.sentences.name],
              })
            );
            content.append(
              insert.scoreCell({
                score: [scoreCat.structure.score, scoreCat.words.score],
                total: [100, 100],
                count: 2,
                label: [scoreCat.structure.name, scoreCat.words.name],
              })
            );
            content.append(
              insert.evaluationCell({
                comment: item.correctData.comment,
              })
            );
            newNode.append(content);
            const etitle = document.createElement("div");
            etitle.className += "etitle";
            etitle.innerHTML = "作文批改分析：";
            newNode.append(etitle, content);
            parent.append(newNode);
          }
        }
      }
    }, 0);
  },
  methods: {
    getScoreColor(score, total) {
      return insert.getScoreColor(score, total);
    },
    toTask(item) {
      if (item.id != this.data.id) {
        const query = {
          ...mapQuery(),
          id: item.id,
        };
        console.log(item,'toTask')
        this.$router.replace({ path: "/pen", query });
      }
    },
    /**填空题**/
    getNewSpanUtil(s,mapTag){
      let ns = document.createElement('span');
      let _s = document.createElement('span');
      _s.style.borderBottom = '1px solid blue';
      _s.style.padding = '0 20px';
      ns.appendChild(_s);

      if(!this.showAnswer){
        if(mapTag[s.name]){
          let flow = mapTag[s.name];
          _s.innerText = flow.answer;
          ns.appendChild(_s);
        }
        return ns;
      }

      let _c = document.createElement('b');
      _c.style.color = 'red';
      _c.innerText = '√';

      let _a = document.createElement('b');
      _a.style.color = 'green';
      _a.style.fontSize = '14px';

      let _r = document.createElement('b');
      _r.style.color = 'green';
      _r.style.fontSize = '14px';

      if(mapTag[s.name]){
        let flow = mapTag[s.name];
        _s.innerText = flow.answer;
        _a.innerText = `( 正确答案：${flow.standardAnswer})`;
        if(flow.subTotalScore == flow.subScore){
          ns.appendChild(_c);
        }else{
          _c.innerText = '×';
          ns.appendChild(_c);
          ns.appendChild(_a);
        }
        if(flow.remark !== ''){
          _r.innerText = ` 注释：${flow.remark}`;
          ns.appendChild(_r);
        }
      }
      return ns;
    },
  },
  components: {
    AnswerSheet,
  },
};
</script>

<style lang="less">
.task-evaluation {
  padding-top: 0.4rem;
  overflow-y: auto;
  .evaluation-detail {
    font-family: "Times New Roman";
    font-size: 10.5pt;
    // font-size: 0.42rem;
    line-height: 1.6em;
  }
  input[type="radio"],
  input[type="text"],
  textarea {
    pointer-events: none;
  }
}
</style>

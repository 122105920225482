<template>
  <div class="homework-page">
    <BeginTask v-if="data.status == '0'" @onBegin="onBegin" :data="data" />
    <div
            class="do-homework"
            v-if="data.status == '1' || data.status == '0'"
            v-show="data.status == '1'"
    >
      <Countdown
              @onFinish="onFinish"
              v-if="data.timeLimitSeconds && data.status == '1'"
              status="countdown"
              :time="(data.timeLeft) * 1000"
      />
      <div class="task-container">
        <div
                :class="`task-detail ${data.paperType == '5' ? 'pb1':''}`"
                :style="data.paperType == '5' ? `height: ${96 - answerSheetVH}vh` : ''"
                id="task-detail"
        >
          <div v-html="data.content"></div>
          <AnswerSheet
                  v-if="data.paperType == '5'"
                  @submit="onSubmit"
                  :answerSheetVH="answerSheetVH"
                  :data="data"
          />
        </div>
        <div class="btn-container" v-if="data.paperType != '5'">
          <van-button @click="onSubmit" round type="primary" block v-if="query.paperDetail!=='1'">完成练习</van-button>
        </div>
      </div>
    </div>
    <!-- <div class="do-homework"> -->
    <div class="do-homework" v-if="data.status == '4' && result.id">
      <div class="task-container">
        <TaskEvaluation :data="data" :result="result" />
      </div>
    </div>
  </div>
</template>

<script>
  import req from "../util/req";
  import { mapQuery } from "../util/URLUtils";
  import Countdown from "../components/Countdown";
  import BeginTask from "../components/BeginTask";
  import AnswerSheet from "../components/AnswerSheet";
  import TaskEvaluation from "../components/TaskEvaluation";
  import { Dialog, Toast, ImagePreview } from "vant";
  import bluePlayImg from "../assets/icon-1_14.png";
  import grayPlayImg from "../assets/icon-1_27.png";

  export default {
    name: "Home",
    data() {
      return {
        location: window.location,
        query: mapQuery(),
        hasEmpty: true,
        data: {},
        result: {},
        answers: [],
        time: 0,
        detailDOM: null,
        audioIndex: 0, // 听力题只允许前面的听完再放后面的
        notPlaying: true, // 听力题一次只允许一个在播放
        audioTagList: null,
        audioBtnList: null,
        radioInputList: null,
        textInputList: null,
        textareaInputList: null,
        answerSheetVH: 50,
      };
    },
    created() {
      this.answers = localStorage.getItem("answer" + this.query.id)
              ? JSON.parse(localStorage.getItem("answer" + this.query.id))
              : [];
      console.log(this.query,'query')
      if(this.query.paperDetail)
        this.getPaperDetail()
      else
        this.getData(true);
    },
    mounted() {},
    methods: {
      postMessage() {
        wx.miniProgram.postMessage({ data: { foo: "bar" } });
      },
      getFormDataFromDOM() {
        let hasEmpty = false;
        // 单选题多个input同一个id，这里用对象储存，避免重复记录
        const radioAnswers = {};
        const answers = [];
        // 获取所有选择题填写答案
        for (let i = 0; i < this.radioInputList.length; i++) {
          const item = this.radioInputList[i];
          const text = item.value;
          const tagId = item.attributes.name.value;
          radioAnswers[tagId] = radioAnswers[tagId] || "";
          if (item.checked) {
            radioAnswers[tagId] = text.slice(0, 1);
          }
        }
        for (const key in radioAnswers) {
          if (!radioAnswers[key]) {
            hasEmpty = true;
          }
          answers.push({
            tagId: key,
            text: radioAnswers[key],
          });
        }
        // 获取所有填空题填写答案
        for (let i = 0; i < this.textInputList.length; i++) {
          const item = this.textInputList[i];
          const text = item.value;
          const tagId = item.attributes.name.value;
          if (!text) {
            hasEmpty = true;
          }
          answers.push({
            tagId,
            text,
          });
        }
        // 获取所有作文题填写答案
        for (let i = 0; i < this.textareaInputList.length; i++) {
          const item = this.textareaInputList[i];
          const text = item.value;
          const tagId = item.attributes.name.value;
          if (!text) {
            hasEmpty = true;
          }
          answers.push({
            tagId,
            text,
          });
        }
        console.log("answers", answers);
        console.log("hasEmpty", hasEmpty);
        localStorage.setItem("answer" + this.query.id, JSON.stringify(answers));
        this.answers = answers;
        this.hasEmpty = hasEmpty;
      },
      // 把缓存的答案填回表单
      answersToInput(answers) {
        for (let i = 0; i < answers.length; i++) {
          const item = answers[i];
          const dom = document.querySelectorAll('[name="' + item.tagId + '"]');
          if (dom.length > 1) {
            for (let j = 0; j < dom.length; j++) {
              if (item.text && dom[j].value.indexOf(item.text) > -1) {
                dom[j].checked = true;
              }
            }
          } else if (dom.length == 1) {
            dom[0].value = item.text;
          }
        }
      },
      getResult() {
        req
                .post("/api/exam/appApi/userTaskResult", {
                  id: this.query.id,
                })
                .then((res) => {
                  res.data.data.averageScore = Math.ceil(res.data.data.averageScore);
                  this.result = res.data.data;
                });
      },
      getData(init) {
        req
                .post("/api/exam/appApi/userTaskDetail", {
                  id: this.query.id,
                })
                .then((res) => {
                  const data = res.data.data || {};
                  this.data = data;
                  const that = this;
                  document.title = data.taskTitle;
                  // 状态（0：待做 1：进行中 2：待上传 3：待批改 4：已批改 5：过期）
                  if (data.status == "0") {
                    // 重置成待做状态后，清除缓存的答案
                    localStorage.setItem("answer" + this.query.id, "[]");
                    this.answers = []
                  } else if (data.status == "2") {
                    Toast("作业已到时间限制，正在上传已填写的作业...");
                    this.doSubmitReq(true, false);
                    return;
                  } else if (data.status == "3") {
                    Toast("试卷批改中，正在返回首页...");
                    setTimeout(() => {
                      wx.miniProgram.redirectTo({
                        url: "/pages/index/index",
                      });
                    }, 1000);
                    return;
                  } else if (data.status == "5") {
                    Toast("试卷已过期，正在返回首页...");
                    setTimeout(() => {
                      wx.miniProgram.redirectTo({
                        url: "/pages/index/index",
                      });
                    }, 1000);
                    return;
                  }
                  if (data.status == "4") {
                    this.getResult();
                  }
                  if (data.content && init) {
                    setTimeout(() => {
                      const detailDOM = document.querySelector("#task-detail");
                      const imgDomList = document.querySelectorAll("#task-detail img");
                      const imgList = []
                      if (imgDomList && imgDomList.length) {
                        for (let i = 0; i < imgDomList.length; i++) {
                          imgList.push(imgDomList[i].src)
                        }
                        for (let i = 0; i < imgDomList.length; i++) {
                          const item = imgDomList[i];
                          item.addEventListener("click", (e) => {
                            const src = e.target.src;
                            ImagePreview({
                              images: imgList,
                              closeable: true,
                              startPosition: i
                            });
                            console.log("imgDomList click", e.target.src);
                          });
                        }
                      }
                      // 插入播放按钮
                      const audioTagList = document.querySelectorAll("audio");
                      if (audioTagList && audioTagList.length > 0) {
                        for (let i = 0; i < audioTagList.length; i++) {
                          const newNode = document.createElement("div");
                          newNode.className = "play-btn-container";
                          newNode.id = "audio" + i;
                          const parent = audioTagList[i].parentNode;
                          // newNode.innerHTML = `
                          //   <svg viewBox="0 0 1060 1060"><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" class="van-circle__hover" style="fill: none; stroke: rgb(255, 255, 255); stroke-width: 60px;"></path><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" stroke="#ff1717" class="van-circle__layer" style="fill: none; stroke: rgb(255, 23, 23); stroke-width: 61px; stroke-dasharray: 0px, 3140px;"></path></svg>
                          //   <img src="${i == 0 ? bluePlayImg : grayPlayImg}" />
                          // `;
                          newNode.innerHTML = `
                    <svg viewBox="0 0 1060 1060"><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" class="van-circle__hover" style="fill: none; stroke: rgb(255, 255, 255); stroke-width: 60px;"></path><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" stroke="#ff1717" class="van-circle__layer" style="fill: none; stroke: rgb(255, 23, 23); stroke-width: 61px; stroke-dasharray: 0px, 3140px;"></path></svg>
                    <img src="${bluePlayImg}" />
                  `;
                          parent.insertBefore(newNode, audioTagList[i]);

                          audioTagList[i].addEventListener("ended", (event) => {
                            that.notPlaying = true;
                            that.audioIndex =
                                    that.audioIndex > i + 1 ? that.audioIndex : i + 1;
                            that.audioBtnList[i].children[0].style.display = "none";
                            that.audioBtnList[i].children[1].src = bluePlayImg;
                            for (let j = 0; j < that.audioBtnList.length; j++) {
                              that.audioBtnList[j].children[1].src = bluePlayImg;
                            }
                            // for (let j = 0; j <= i; j++) {
                            //   that.audioBtnList[j].children[1].src = bluePlayImg;
                            // }
                            // if (that.audioBtnList[i + 1]) {
                            //   that.audioBtnList[i + 1].children[1].src = bluePlayImg;
                            // }
                          });
                          audioTagList[i].addEventListener("timeupdate", (event) => {
                            const result =
                                    (that.audioTagList[i].currentTime /
                                            that.audioTagList[i].duration) *
                                    3140;
                            that.audioBtnList[
                                    i
                                    ].children[0].children[1].style.strokeDasharray =
                                    result + "" + "px 3140px";
                          });
                          audioTagList[i].addEventListener("play", (event) => {
                            that.audioBtnList[i].children[0].style.display = "block";
                            for (let j = 0; j < that.audioBtnList.length; j++) {
                              that.audioBtnList[j].children[1].src = grayPlayImg;
                            }
                          });
                        }
                      }
                      that.audioTagList = audioTagList;
                      setTimeout(() => {
                        const audioBtnList = document.querySelectorAll(
                                ".play-btn-container"
                        );
                        for (let i = 0; i < audioBtnList.length; i++) {
                          if (audioBtnList[i]) {
                            audioBtnList[i].children[1].style.display = "block";
                            audioBtnList[i].addEventListener("click", (e) => {
                              const item = e.currentTarget;
                              // 当前点击的第几个audio
                              const index = Number(item.id.slice(5));
                              if (that.notPlaying) {
                                // if (that.audioIndex >= index && that.notPlaying) {
                                that.notPlaying = false;
                                that.audioTagList[index].play();
                              }
                            });
                          }
                        }
                        that.audioBtnList = audioBtnList;
                      }, 0);
                      that.detailDOM = detailDOM;
                      if (!detailDOM) {
                        return;
                      }
                      that.radioInputList = document.querySelectorAll(
                              'input[type="radio"]'
                      );
                      that.textInputList = document.querySelectorAll(
                              'input[type="text"]'
                      );
                      that.textareaInputList = document.querySelectorAll("textarea");
                      // 首次初始化页面时绑定事件 监听输入变化 重复执行此函数结果不变
                      detailDOM.addEventListener("click", (e) => {
                        console.log("click");
                        that.getFormDataFromDOM();
                      });
                      // detailDOM.addEventListener("touchend", () => {
                      //   console.log("touchend");
                      //   that.getFormDataFromDOM();
                      // });
                      const clientWidth = (document.body.clientWidth||document.getElementsByTagName("html")[0].offsetWidth) * .92
                      for (let i = 0; i < that.textInputList.length; i++) {
                        that.textInputList[i].addEventListener("input", (e) => {
                          that.getFormDataFromDOM();
                          let width = Math.max(that.textInputList[i].scrollWidth, 80)
                          width = Math.min(width, clientWidth)
                          that.textInputList[i].style.width = width + 'px'
                        });
                        setTimeout(() => {
                          let width = Math.max(that.textInputList[i].scrollWidth, 80)
                          width = Math.min(width, clientWidth)
                          that.textInputList[i].style.width = width + 'px'
                        }, 100)
                      }
                      for (let i = 0; i < that.textareaInputList.length; i++) {
                        that.textareaInputList[i].addEventListener("input", (e) => {
                          console.log("inputarea");
                          that.getFormDataFromDOM();
                          that.textareaInputList[i].style.height = that.textareaInputList[i].scrollHeight + 'px'
                          console.log('.scrollHeight', that.textareaInputList[i].scrollHeight)
                        });
                        setTimeout(() => {
                          that.textareaInputList[i].style.height = that.textareaInputList[i].scrollHeight + 'px'
                        }, 100)
                      }
                      if (that.answers && that.answers.length) {
                        setTimeout(() => {
                          that.answersToInput(that.answers);
                          that.getFormDataFromDOM();
                        }, 0);
                      }
                    }, 0);
                  }
                });
      },
      onBegin() {
        req
                .post("/api/exam/appApi/userTaskStart", {
                  id: this.query.id,
                })
                .then((res) => {
                  const errorCode = res.data.errorCode;
                  const errorDesc = res.data.errorDesc;
                  if (errorCode == "900052") {
                    Dialog.confirm({
                      title: "提示",
                      message: errorDesc,
                      allowHtml: true,
                    }).then(() => {
                      wx.miniProgram.redirectTo({
                        url: "/pages/index/index",
                      });
                    });
                  } else if (errorCode) {
                    Toast(errorDesc);
                  } else {
                    this.data = {
                      ...this.data,
                      ...res.data.data,
                      content: this.data.content,
                      answerSheet: this.data.answerSheet
                    };
                  }
                });
      },
      onSubmit() {
        if (this.hasEmpty) {
          Dialog.confirm({
            title: "完成练习",
            message:
                    '<div class="red">还有未完成的题目，是否仍要提交练习？</div>',
            allowHtml: true,
          }).then(() => {
            this.doSubmitReq(true, true);
          });
        } else {
          Dialog.confirm({
            title: "完成练习",
            message: "是否提交该练习",
            allowHtml: true,
          }).then(() => {
            this.doSubmitReq(true, true);
          });
        }
      },
      doSubmitReq(backToIndex, showToast) {
        req
                .post("/api/exam/appApi/userTaskSubmit", {
                  id: this.query.id,
                  answers: this.answers.map(answer => {
                    answer.text = answer.text.replace(/(^\s*)|(\s*$)/g, "")
                    return answer
                  }),
                })
                .then((res) => {
                  const errorCode = res.data.errorCode;
                  const errorDesc = res.data.errorDesc;
                  if (errorCode == "900088") {
                    Dialog.confirm({
                      title: "提示",
                      message: errorDesc,
                      allowHtml: true,
                    }).then(() => {});
                    return;
                  }
                  if (errorCode == "900052") {
                    Dialog.confirm({
                      title: "提示",
                      message: errorDesc,
                      allowHtml: true,
                    }).then(() => {
                      wx.miniProgram.redirectTo({
                        url: "/pages/index/index",
                      });
                    });
                    return;
                  }
                  if (res.data.success) {
                    if (showToast) {
                      Toast("作业提交成功！");
                    }
                    if (backToIndex) {
                      wx.miniProgram.redirectTo({
                        url: "/pages/index/index",
                      });
                    }
                  }
                });
      },
      onFinish() {
        this.doSubmitReq(false, false);
        Dialog.confirm({
          title: "提示",
          message: "时间限制到了，作答结束，已做答案会自动上传",
          showCancelButton: false,
        }).then(() => {
          wx.miniProgram.redirectTo({
            url: "/pages/index/index",
          });
          // this.doSubmitReq(true, true);
        });
      },
      getPaperDetail(){
        req
                .post(this.query.resourceType=='1'?"/api/exam/paperDetail":"/api/exam/materialPaperDetail", {
                  id: this.query.id,
                })
                .then((res) => {
                  const data = res.data.data || {};
                  this.data = data;
                  const that = this;
                  document.title = data.title;
                  if (data.content) {
                    setTimeout(() => {
                      const detailDOM = document.querySelector("#task-detail");
                      const imgDomList = document.querySelectorAll("#task-detail img");
                      const imgList = []
                      if (imgDomList && imgDomList.length) {
                        for (let i = 0; i < imgDomList.length; i++) {
                          imgList.push(imgDomList[i].src)
                        }
                        for (let i = 0; i < imgDomList.length; i++) {
                          const item = imgDomList[i];
                          item.addEventListener("click", (e) => {
                            const src = e.target.src;
                            ImagePreview({
                              images: imgList,
                              closeable: true,
                              startPosition: i
                            });
                            console.log("imgDomList click", e.target.src);
                          });
                        }
                      }
                      // 插入播放按钮
                      const audioTagList = document.querySelectorAll("audio");
                      if (audioTagList && audioTagList.length > 0) {
                        for (let i = 0; i < audioTagList.length; i++) {
                          const newNode = document.createElement("div");
                          newNode.className = "play-btn-container";
                          newNode.id = "audio" + i;
                          const parent = audioTagList[i].parentNode;
                          newNode.innerHTML = `
                    <svg viewBox="0 0 1060 1060"><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" class="van-circle__hover" style="fill: none; stroke: rgb(255, 255, 255); stroke-width: 60px;"></path><path d="M 530 530 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000" stroke="#ff1717" class="van-circle__layer" style="fill: none; stroke: rgb(255, 23, 23); stroke-width: 61px; stroke-dasharray: 0px, 3140px;"></path></svg>
                    <img src="${bluePlayImg}" />
                  `;
                          parent.insertBefore(newNode, audioTagList[i]);

                          audioTagList[i].addEventListener("ended", (event) => {
                            that.notPlaying = true;
                            that.audioIndex =
                                    that.audioIndex > i + 1 ? that.audioIndex : i + 1;
                            that.audioBtnList[i].children[0].style.display = "none";
                            that.audioBtnList[i].children[1].src = bluePlayImg;
                            for (let j = 0; j < that.audioBtnList.length; j++) {
                              that.audioBtnList[j].children[1].src = bluePlayImg;
                            }
                          });
                          audioTagList[i].addEventListener("timeupdate", (event) => {
                            const result =
                                    (that.audioTagList[i].currentTime /
                                            that.audioTagList[i].duration) *
                                    3140;
                            that.audioBtnList[
                                    i
                                    ].children[0].children[1].style.strokeDasharray =
                                    result + "" + "px 3140px";
                          });
                          audioTagList[i].addEventListener("play", (event) => {
                            that.audioBtnList[i].children[0].style.display = "block";
                            for (let j = 0; j < that.audioBtnList.length; j++) {
                              that.audioBtnList[j].children[1].src = grayPlayImg;
                            }
                          });
                        }
                      }
                      that.audioTagList = audioTagList;
                      setTimeout(() => {
                        const audioBtnList = document.querySelectorAll(
                                ".play-btn-container"
                        );
                        for (let i = 0; i < audioBtnList.length; i++) {
                          if (audioBtnList[i]) {
                            audioBtnList[i].children[1].style.display = "block";
                            audioBtnList[i].addEventListener("click", (e) => {
                              const item = e.currentTarget;
                              // 当前点击的第几个audio
                              const index = Number(item.id.slice(5));
                              if (that.notPlaying) {
                                // if (that.audioIndex >= index && that.notPlaying) {
                                that.notPlaying = false;
                                that.audioTagList[index].play();
                              }
                            });
                          }
                        }
                        that.audioBtnList = audioBtnList;
                      }, 0);
                      that.detailDOM = detailDOM;
                      if (!detailDOM) {
                        return;
                      }
                      that.radioInputList = document.querySelectorAll(
                              'input[type="radio"]'
                      );
                      that.textInputList = document.querySelectorAll(
                              'input[type="text"]'
                      );
                      that.textareaInputList = document.querySelectorAll("textarea");
                      // 首次初始化页面时绑定事件 监听输入变化 重复执行此函数结果不变
                      detailDOM.addEventListener("click", (e) => {
                        that.getFormDataFromDOM();
                      });
                      const clientWidth = (document.body.clientWidth||document.getElementsByTagName("html")[0].offsetWidth) * .92
                      for (let i = 0; i < that.textInputList.length; i++) {
                        that.textInputList[i].addEventListener("input", (e) => {
                          that.getFormDataFromDOM();
                          let width = Math.max(that.textInputList[i].scrollWidth, 80)
                          width = Math.min(width, clientWidth)
                          that.textInputList[i].style.width = width + 'px'
                        });
                        setTimeout(() => {
                          let width = Math.max(that.textInputList[i].scrollWidth, 80)
                          width = Math.min(width, clientWidth)
                          that.textInputList[i].style.width = width + 'px'
                        }, 100)
                      }
                      for (let i = 0; i < that.textareaInputList.length; i++) {
                        that.textareaInputList[i].addEventListener("input", (e) => {
                          console.log("inputarea");
                          that.getFormDataFromDOM();
                          that.textareaInputList[i].style.height = that.textareaInputList[i].scrollHeight + 'px'
                          console.log('.scrollHeight', that.textareaInputList[i].scrollHeight)
                        });
                        setTimeout(() => {
                          that.textareaInputList[i].style.height = that.textareaInputList[i].scrollHeight + 'px'
                        }, 100)
                      }
                      if (that.answers && that.answers.length) {
                        setTimeout(() => {
                          that.answersToInput(that.answers);
                          that.getFormDataFromDOM();
                        }, 0);
                      }
                    }, 0);
                  }
                });
      }
    },
    components: {
      Countdown,
      BeginTask,
      TaskEvaluation,
      AnswerSheet,
    },
  };
</script>

<style lang="less">
  .task-container {
    padding: 0 4% 0.4rem;
    .van-action-sheet {
      min-height: 20%;
      width: 92%;
      left: 4%;
      bottom: 4%;
      box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2);
      border-radius: 0.1rem;
    }
    .van-action-sheet__content {
      height: 100%;
    }
  }
  .task-detail {
    padding-bottom: 0.3rem;
    overflow-y: auto;
    font-family: 'Times New Roman';
    font-size: 10.5000pt;
    // font-size: 0.42rem;
    line-height: 1.6em;
  }

  .MsoNormal{
    font-size: .42rem;
  }

  .btn-container {
    padding: 0.3rem;
  }
</style>

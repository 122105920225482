import Vue from 'vue'
import VueRouter from 'vue-router'
import PenAndComp from '../views/PenAndComp.vue'

Vue.use(VueRouter)

  const routes = [
  {
    // 综合卷
    path: '/pen',
    name: 'pen',
    component: PenAndComp
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
